import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Player from '../components/player';

interface MatchParams {
  stream: string;
}

export type VODProps = RouteComponentProps<MatchParams>;

export interface VODState {
  source: string;
}

class VOD extends React.Component<VODProps, VODState> {
  constructor(props: VODProps) {
    super(props);

    const {
      match: {
        params: { stream },
      },
    } = this.props;

    this.state = {
      source: `https://media3.hostin.cc/store/${stream}.mp4`,
    };
  }

  componentDidMount() {
    document.title = 'Video';
  }

  render() {
    const { source } = this.state;

    return (
      <div className="container">
        <Player
          source={source}
          hls={false}
          controls={[
            'play-large',
            'play',
            'progress',
            'current-time',
            'volume',
            'fullscreen',
          ]}
        />
      </div>
    );
  }
}

export default VOD;
